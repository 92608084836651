//import './css/bootstrap.min.css';
import bootstrap from 'bootstrap';
import $ from 'jquery';
import './css/style.css';
import './css/signin.css';
import "./node_modules/ol/ol.css";

import {Map, View} from 'ol';
import {defaults as interactionDefaults} from 'ol/interaction';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import OSM from 'ol/source/OSM';
import GPX from 'ol/format/GPX.js';
import VectorSource from 'ol/source/Vector';
import Overlay from 'ol/Overlay';
import {LineString,Point} from 'ol/geom';
import {Style, Fill,Circle, Stroke} from 'ol/style';
import Feature from 'ol/Feature';
import * as olProj from 'ol/proj';

// Global data
var map,
    overlay,
    layerTracks;

// **** Main ****

   if (document.domain == "localhost" || document.domain == "192.168.2.107"){
      var baseURL =  'http://' + document.domain + '/tracking'
   }else{
      var baseURL =  '.'
   }

   var isMobile = window.matchMedia("only screen and (max-width: 760px)");

   const form = document.forms[0];
   
   const store = {};
   // Inserts the jwt to the store object
   store.setJWT = function (data) {
      this.JWT = data;
   };
   
   var token = getCookie("token");
   if (token != "") {
      store.setJWT(token);
      $('#frmLogin').hide();
      setupTracklist();
      $('#page-map').css('visibility', 'visible');
   }
   
   setupMap();

   $("#frmLogin" ).submit(function( e ) {

      e.preventDefault();

      //-----------------------------------------------------------------------
      // Get JWT token
      //-----------------------------------------------------------------------
      $.ajax({
         type: 'post',
         url: baseURL + '/authenticate.php',          // Replace by https in production
         async: false,
         crossDomain: true,
         dataType: "json",
         headers: {
            //'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
            'Content-type': 'application/json; charset=UTF-8'
         },
         data: JSON.stringify({
          username: form.inputEmail.value,
          password: form.inputPassword.value
         }),
         success: function(data) {
            if (data.token == ""){
               alert('Falscher Username/Passwort');
            }else{
               const jwt = data.token;
               store.setJWT(jwt);
               document.cookie = "token=" + data.token + "; SameSite=Strict; Secure";
               $('#frmLogin').hide();
               $('#page-map').show();
               setupTracklist();
               $('#page-map').css('visibility', 'visible');
            }
         },
         error: function( jqXHR, textStatus, errorThrown) {
           alert('There was some error performing the AJAX call!');
           alert(textStatus+'\n'+errorThrown);
         }
      });

   });
   
function setupMap (){
   //-----------------------------------------------------------------------
   // Setup Map
   //-----------------------------------------------------------------------
   map = new Map({
     target: 'map',
     interactions: interactionDefaults({mouseWheelZoom: true, pinchRotate: false}),
     layers: [
       new TileLayer({
         source: new OSM()
       })
     ],
     view: new View({
       center: [0, 0],
       zoom: 2,
       constrainResolution: true,   // necessary to prevent blurry map on zooming
       maxZoom: 19,
     })
   });

   //-----------------------------------------------------------------------
   // Add track layer + source
   //-----------------------------------------------------------------------
   var sourceTracks = new VectorSource({
      format: new GPX(),
   });
   
   layerTracks = new VectorLayer({
       source: sourceTracks,
   });

   map.addLayer (layerTracks);

   //-----------------------------------------------------------------------
   // Add popup layer
   //-----------------------------------------------------------------------

   overlay = new Overlay({
      element: document.getElementById('popup'),
      autoPan: true,
      autoPanAnimation: {
         duration: 250,
      },
   });

   map.addOverlay(overlay);


   // Add a click handler to hide the popup.

   document.getElementById('popup-closer').onclick = function () {
     overlay.setPosition(undefined);
     $('#popup-closer').blur();
     map.on('pointermove', clickHandler);
     return false;
   };

   // Add a click handler to the map to render the popup.

   map.on(['singleclick','pointermove'], clickHandler);
   //map.on(['singleclick'], clickHandler);

   // Dropdown menu (mobile only):
   const navToggle = document.getElementById('nav-toggle');
   const sidebar = document.querySelector('.sidebar');

   navToggle.addEventListener('change', (e) => {
      if (e.target.checked) {
          sidebar.style = 'display: block';
        } else {
          sidebar.style = 'display: none';
        }
    });
    
    
   // Teilen-Button im Popup
   $('#popup-share-button').on('click', async () => {
     try {
       const mapsUrl = 'https://www.google.com/maps?q='+$('#popup-lat').html()+','+$('#popup-lon').html();
       await navigator.share({
         title: "Test",
//       text:  "Position am " + $('#popup-date-location').html(),
         text:  "Location: " + mapsUrl,
         url:   mapsUrl,
       });
     } catch (err) {
       console.error("error:", err.message);
     }
   });
}


//-----------------------------------------------------------------------
// Display list of days with location data
//-----------------------------------------------------------------------
function setupTracklist () {
   $.ajax({
      url: baseURL + '/getTrackList.php',
      //async: false,
      crossDomain: true,
      dataType: "json",
      headers: {
         'Authorization': `Bearer ${store.JWT}`
      },
      success: function(data) {
         $.each( data, function( key, val ) {
            var checkBox = $(  '<div class="checkbox"><input type="checkbox" class="track" date="'+val['date']+'"/></div>'
                             + '<div class="checkbox-text"><p>'+val['date']+'</p></div>'
                             + '<div class="download"><button class="button-gpx" date="'+val['date']+'">GPX</button></div>'
                           );
            checkBox.appendTo('.trackSelect');
         });

         $('.button-gpx').on('click', function(e){
            e.preventDefault();
            saveTrackToGpxFile($(this).attr('date'));
         });




         // Neuesten Track laden 
         $('input[type=checkbox][class=track]').first().prop('checked', true);
         addTrack($('input[type=checkbox][class=track]').first().attr('date'));
         // Event-Funktion bei Track-Auswahl
         $('.trackSelect').on('click', function() {
            $('input[type=checkbox][class=track]').each(function() {
               if ($(this).is(':checked')) {
                  // checked item
                     addTrack($(this).attr('date'));
               }else {
                   // unchecked item
                    removeTrack($(this).attr('date'));
               }
            });
         });
      },
      error: function( jqXHR, textStatus, errorThrown) {
         if (jqXHR.status == 200){
            alert(jqXHR.responseText);
         }else{
            alert(textStatus + '\n' + errorThrown);
            $('#page-map').hide();
            $('#frmLogin').show();
         }
      }
   });

   //--------------------------------------------------------
   // Track selection
   //--------------------------------------------------------
}

//------------------------------------------------------------------------------
// Display track on map
//------------------------------------------------------------------------------
function addTrack(dt) {
   var i;

   var $source = layerTracks.getSource();
   var $features = $source.getFeatures();
   for (i = 0; i < $features.length; i++) {
      if ($features[i].get('date') === dt) {
         return
      }
   }
   
   $.ajax({
      url: baseURL + '/getTrack.php',
      type: "POST",
      //async: false,
      dataType: "json",
      data: ({date: dt}),
      headers: {
         'Authorization': `Bearer ${store.JWT}`
      },
      success: function(data) {
         // Add track to map:
         var lineCoords = [];
         var $features = [];
         
         // create track feature: 
         $.each( data, function( key, val ) {
            lineCoords.push(olProj.transform([val['lon'],val['lat']], 'EPSG:4326','EPSG:3857'));
         });
         var $trackFeature = new Feature({
            geometry: new LineString(lineCoords)
         });
         var lineStyle = new Style({    
            stroke: new Stroke({
               color: 'rgba(255,0,0,1.0)',
               width: 4
            }),
            zIndex: 150,
         });
         $trackFeature.setStyle(lineStyle);
         $trackFeature.set('date', dt)
         
         $features.push($trackFeature)

         // set map extent to current track:
         var $sourceTmp = new VectorSource({ });
         $sourceTmp.addFeature($trackFeature);
         var extent = $sourceTmp.getExtent();
         map.getView().fit(extent, map.getSize());
 
         // Add position markers to map:
         var markerStyleStd = new Style({    
            image: new Circle({
               radius: 5,
               fill: new Fill({color: 'rgba(255,0,0,1.0)'})
            }),
            zIndex: 100,
         });
         var markerStyleLast = new Style({    
            image: new Circle({
               radius: 5,
               fill: new Fill({color: 'rgba(0,255,0,1.0)'})
            }),
             zIndex: 200,
         });
         $.each( data, function( key, val ) {
            var $pointFeature = new Feature({
                  geometry: new Point(olProj.transform([val['lon'],val['lat']], 'EPSG:4326','EPSG:3857'))
            });
            if (key != data.length-1){
               $pointFeature.setStyle(markerStyleStd);
            }else{
               $pointFeature.setStyle(markerStyleLast);
            }
            $pointFeature.set('date', dt);
            var date = new Date(val['dt'] + ' UTC');
            let strDate = date.toLocaleString();
            $pointFeature.set('datetime', strDate);
            $pointFeature.set('lon', val['lon']);
            $pointFeature.set('lat', val['lat']);
            date = new Date(val['created_at'] + ' UTC');
            strDate = date.toLocaleString();
            $pointFeature.set('created_at', strDate);
            $features.push($pointFeature);
         });
         
         $source.addFeatures($features);

      },
      error: function( jqXHR, textStatus, errorThrown) {
         if (jqXHR.status == 200){
            alert(jqXHR.responseText);
         }else{
            alert(textStatus + '\n' + errorThrown);
            $('#page-map').hide();
            $('#frmLogin').show();
         }
      }
   });
}

function removeTrack(dt) {
   var i;
   var $features = layerTracks.getSource().getFeatures();
   for (i = 0; i < $features.length; i++) {
      if ($features[i].get('date')) {
         if ($features[i].get('date') == dt) {
            layerTracks.getSource().removeFeature($features[i]);
         }
      }
   }
}


//------------------------------------------------------------------------------
// Click Handler
//------------------------------------------------------------------------------
function clickHandler (evt) {
   var feature, $features = [];
   var hitTol = 8;
   if (isMobile.matches){
      hitTol = 15;
   }
   map.forEachFeatureAtPixel(evt.pixel,
      function(feature) {
          $features.push(feature);
   }, {hitTolerance: hitTol});

   for (var i = 0, ii = $features.length; i < ii; ++i) {
      if (feature) {
         var t1 = feature.getGeometry().getType();
         var t2 = $features[i].getGeometry().getType();
         if (t1 == 'LineString' && t2 == 'Point') {
            feature = $features[i];
         }else if (t1 == 'Point' && t2 == 'Point') {
            // Display data of most recent track point in case of nearby points:
            if ($features[i].getGeometry()['dt'] > feature.getGeometry()['dt']){ 
               feature = $features[i];
            }
         }
      } else {
         feature = $features[i];
      }
   }
   if (feature) {
      if (evt.type === 'singleclick') {
         // Disable popup on hover
         map.un('pointermove', clickHandler);
      }
      var prop = feature.getProperties();
      if (feature.getGeometry().getType() == 'Point'){
         document.getElementById('popup-date-location').innerHTML = prop['datetime'];
         $('#popup-date').html(prop['created_at']);
         $('#popup-lon').html(prop['lon']);
         $('#popup-lat').html(prop['lat']);
         $('#showLocation').attr('href', 'geo:'+prop['lat']+','+prop['lon']);
         if (! $.isFunction(navigator.share)){
            $('#popup-share').hide();
         }
      }else{
         $('#popupDate').html = prop['date'];
         $('#popupLon').html = '';
         $('#popupLat').html = '';
      }
      overlay.setPosition(evt.coordinate);
   }else{
      overlay.setPosition(undefined);
      map.on('pointermove', clickHandler);
      if (evt.type === 'singleclick') {
         if ($('#nav-toggle').css('display') != 'none'){
            $('.sidebar').css('display', 'none');
         }
      }
   }
      
}
//------------------------------------------------------------------------------
// getCookie
//------------------------------------------------------------------------------
function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

//------------------------------------------------------------------------------
// saveTrackToGpxFile - Track als GPX-Datei speichern
// 
// Quelle: Grok3
//
// Schreibt MultilinesString-Feature mit OL in GPX-Datei. Für Trackpunkte wird 
// daher nur lat/lon geschrieben.
//
//------------------------------------------------------------------------------
function saveTrackToGpxFile(date) {
   // Get the source from the database
   $.ajax({
      url: baseURL + '/getTrack.php',
      type: "POST",
      //async: false,
      dataType: "json",
      data: ({date: date}),
      headers: {
         'Authorization': `Bearer ${store.JWT}`
      },
      success: function(data) {
         downloadGPX(data, date);
      },
      error: function( jqXHR, textStatus, errorThrown) {
         if (jqXHR.status == 200){
            alert(jqXHR.responseText);
         }else{
            alert(textStatus + '\n' + errorThrown);
         }
      }
   })
}

/*------------------------------------------------------------------------------
 * downloadGPX
 *------------------------------------------------------------------------------
 * Creates a GPX file for download from a multilinestring feature
 *----------------------------------------------------------------------------*/
function downloadGPX(trackData, trackDate){
   // Create a GPX string from feature
   const gpxString = trackToGPX(trackDate, trackData);
   // Create a Blob with the GPX data
   const blob = new Blob([gpxString], { type: 'application/gpx+xml' });
   // Create a temporary link element for download
   const link = document.createElement('a');
   const url = URL.createObjectURL(blob);
   link.href = url;
   link.download = trackDate + '.gpx';
   // Programmatically trigger the download
   document.body.appendChild(link);
   link.click();
   // Clean up
   document.body.removeChild(link);
   URL.revokeObjectURL(url);
}

/*------------------------------------------------------------------------------
 * lonLatToFeature
 *------------------------------------------------------------------------------
 * 
 *----------------------------------------------------------------------------*/
function trackToPointFeatures(data){
   let arrayFeatures = [];
   $.each( data, function( key, val ) {
      var pointFeature = new Feature({
            geometry: new Point(olProj.transform([val['lon'],val['lat']], 'EPSG:4326','EPSG:3857'))
      });
      pointFeature.set('time', val['dt']);
      pointFeature.set('ele', val['alt']);
      arrayFeatures.push(pointFeature);
   });
   return arrayFeatures;
}

/*------------------------------------------------------------------------------
 * featureToGPX
 *------------------------------------------------------------------------------
 * Creates a GPX feature from a multilinestring feature
 *----------------------------------------------------------------------------*/
/*
function featureToGPX($feature){
   const gpxFormat = new GPX();
   // Convert feature to GPX string
   const gpxString = gpxFormat.writeFeatures([$feature], {
      featureProjection: 'EPSG:3857', // Adjust based on your map's projection
      dataProjection: 'EPSG:4326',    // GPX uses WGS84 (EPSG:4326)
   });
   return gpxString;
}
*/
function trackToGPX(trackDate, data){

   // Start building the GPX string
   let gpxString = '<?xml version="1.0" encoding="UTF-8"?>'
                  + '<gpx version="1.1" creator="OpenLayers" xmlns="http://www.topografix.com/GPX/1/1">\n'
                  + `<trk>\n<name>${trackDate}</name>`;

   // Iterate over array of points:
   gpxString += '<trkseg>\n';
   $.each(data, function( key, val ){
      //<time>2024-12-03T12:56:34Z</time>
      const date = new Date(val['created_at'] + " UTC");
      const dateISO = date.toISOString();
      const desc = `Batterie: ${val['batt']}, ` 
                    + `Auslöser: ${val['t']}, `
                    + `Genauigk.: ${val['acc']}, `
                    + `Geschw.: ${val['vel']}, `
                    + `Verbindung: ${val['conn']}, ` 
                    + `SSID: ${val['ssid']}`;

      gpxString += `<trkpt lat="${val['lat']}" lon="${val['lon']}">`
                   + `<ele>${val['alt']}</ele>`
                   + `<time>${dateISO}</time>`
                   + `<desc>${desc}</desc>`
                   + `</trkpt>\n`;
   });
   gpxString += '</trkseg>\n';
  // Close the GPX tags
  gpxString += '</trk>\n</gpx>';

  return gpxString;
}
